import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./review-info-modals.module.scss";
import TGModal from "../../../../../../shared/tg-modal";
import TGIcon from "../../../../../../shared/tg-icon";
import { cancelBookingPNRRequest } from "../../../../slice/cancelBookingPNRSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import termsAndConditionsReviewInfo from "../../../../../../../public/json/termsAndConditionsReviewInfo.json";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { constant } from "../../../../../../config/constant";
import { camelCase } from "../../../../../../utils/helper";
import { useClassObserver } from "../../../../../../utils/hook/useClassObserver";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import { resetPersistStore } from "../../../../../../store";
import {
  clearSelectedFlightIndex,
  clearSelectedFlightInfoData,
  resetRedemeptionBookingData,
} from "../../../../slice/flightInfoSlice";

interface IProps {
  hidePanel: () => void;
  componentName?: string;
  baggageData?: Array<any>;
  priceBreakDownData?: any;
  retrivePNRData?: any;
}
const ReviewInfoModals = (props: IProps) => {
  const {
    review_info_silver,
    review_info_gold,
    review_info_platinum,
    review_info_member,
    baggage_info,
    baggage_text,
  } = constant;
  const [show, setShow] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const handleClose = () => {
    setShow(false);
    props.hidePanel();
  };

  const cancelBookingPNRData = useSelector(
    (state: RootState) => state?.osciReducer?.cancelBookingPNRData
  );

  const airAwardBookingData = useSelector(
    (state: RootState) =>
      state?.osciReducer?.airAwardBooking?.airAwardBookingInfo
  );

  const isDesktopModalExists = useClassObserver("desktop-modal");
  useEffect(() => {
    if (!isMobile) {
      if (isDesktopModalExists) {
        document.documentElement.style.setProperty("--html-width", "100%");
      }
    }
  }, [isDesktopModalExists, isMobile]);

  const onclickConfirmAndLeaveBtn = () => {
    const reqData = {
      bookingRefId: airAwardBookingData?.data?.bookingRefId,
      session: {
        sessionId:
          props?.retrivePNRData?.session?.sessionId &&
          props?.retrivePNRData?.session?.sessionId,
        sequenceNumber:
          props?.retrivePNRData?.session?.sequenceNumber &&
          props?.retrivePNRData?.session?.sequenceNumber,
        securityToken:
          props?.retrivePNRData?.session?.securityToken &&
          props?.retrivePNRData?.session?.securityToken,
      },
    };
    resetPersistStore();
    dispatch(cancelBookingPNRRequest(reqData));
  };

  useEffect(() => {
    if (cancelBookingPNRData?.codeStatus === "success") {
      handleClose();
      sessionStorage?.removeItem("entry");
      dispatch(clearSelectedFlightInfoData());
      dispatch(clearSelectedFlightIndex());
      dispatch(resetRedemeptionBookingData());
      resetPersistStore();
      navigate(`/${i18n.language}/`);
    }
  }, [cancelBookingPNRData?.codeStatus]);

  const onClickClosePopup = () => {
    handleClose();
  };

  return (
    <>
      {props.componentName === "Cancelbutton" && (
        <TGModal
          show={show}
          handleClose={handleClose}
          buttonLabel="Ok"
          customModalClass={styles.customModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.cancelRedeemDiv}>
            <div className={styles.cancelRedeemContent}>
              <div className={styles.iconModalIllustration}>
                <div className={styles.groupIcons}>
                  <div className={styles.iconlayout}>
                    <TGIcon
                      icon="review-info-modal-layout-icon"
                      fillColor=""
                      size="10px"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.textLayout}>
                <div className={styles.cancelRedeem}>
                  <span className={styles.cancleThisRedeemLabel}>
                    {t("review_info_cancel_redemption")}
                  </span>
                </div>
                <span className={styles.cancelConfirmationLabel}>
                  {t("review_info_cancel_confirmation_label")}
                </span>
              </div>
            </div>
            <div className={styles.closeIcon} onClick={handleClose}>
              <TGIcon
                icon="review-info-cancel-close-icon"
                fillColor=""
                size="10px"
              />
            </div>
          </div>
          <div className={styles.stayAndCancelBtnDiv}>
            <div className={styles.confirmAndLeaveDiv}>
              <button
                className={styles.confirmAndLeaveBtn}
                onClick={onclickConfirmAndLeaveBtn}
              >
                <span className={styles.confirmAndLeaveLabel}>
                  {t("review_info_confirm_and_leave")}
                </span>
              </button>
            </div>
            <div className={styles.stayBtnDiv}>
              <button className={styles.stayButton} onClick={onClickClosePopup}>
                <span className={styles.stayTextLabel}>Stay</span>
              </button>
            </div>
          </div>
        </TGModal>
      )}
      {props.componentName === "PriceBreakDown" && (
        <TGModal
          show={show}
          handleClose={handleClose}
          buttonLabel="Ok"
          heading={t("review_info_price_breakdown_label")}
          customModalClass={styles.priceBreakDownModalClass}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.priceBreakdownPopup}>
            {props.priceBreakDownData?.fareInfo?.map(
              (fareInfoDetails: any, index: any) => (
                <div className={styles.passengersDetails}>
                  <div className={styles.passengersDetailsDiv}>
                    <span className={styles.PassengerNameLabel}>
                      {camelCase(fareInfoDetails?.firstName)}{" "}
                      {camelCase(fareInfoDetails?.lastName)} {"("}
                      {fareInfoDetails?.type}
                      {")"}
                    </span>
                    <div className={styles.fareDetailsDiv}>
                      <div className={styles.fareAndCostDiv}>
                        <span className={styles.fareLabel}>
                          {t("label_redemption_summary_fare")}
                        </span>
                        <span className={styles.costOfMilesLabel}>
                          {t("review_info_cost_in_miles")}
                        </span>
                        <span className={styles.costTHBLabel}>
                          {t("review_info_cost_in_thb")}
                        </span>
                      </div>
                      <div className={styles.fareValueDiv}>
                        <span className={styles.fareValueLabel}>
                          {t("label_redemption_summary_fare")}
                        </span>
                        <span className={styles.costInMilesValue}>
                          {fareInfoDetails?.totalMiles}
                        </span>
                        <span className={styles.costTHBValue}>-</span>
                      </div>
                    </div>
                    <div className={styles.subTotalDiv}>
                      <span className={styles.subtotalLabel}>
                        {t("label_redemption_summary_subtotal")}
                      </span>
                      <span className={styles.subtotalCostOfMilesValue}>
                        {fareInfoDetails?.totalMiles}
                      </span>
                      <span className={styles.subtotalCostOfTHBVal}>
                        {fareInfoDetails?.totalFares}
                      </span>
                    </div>
                  </div>
                  <div className={styles.verticalLinePassengers}></div>
                </div>
              )
            )}

            <div className={styles.taxesAndSurchangesDetails}>
              <div className={styles.taxesAndSurchangesDiv}>
                <span className={styles.taxesAndSurchangesLabel}>
                  {t("label_redemption_summary_taxes_and_surchanges")}
                </span>
              </div>
              {props.priceBreakDownData?.taxInfo.map(
                (taxInfoDetails: any, index: any) => (
                  <div className={styles.listOftaxes}>
                    <span className={styles.taxTextLabel}>Tax {index + 1}</span>
                    <span className={styles.taxCostOfMilesVal}>-</span>
                    <span className={styles.taxCostOfTHBVal}>
                      {taxInfoDetails?.amount}
                    </span>
                  </div>
                )
              )}
            </div>
            <div className={styles.verticalLine}></div>
            <div className={styles.grandTotalDiv}>
              <span className={styles.grandTotalLabel}>
                {t("review_info_grand_total_label")}
              </span>
              <span className={styles.grandTotalMilesValue}>
                {props?.priceBreakDownData?.totalMiles}{" "}
                {t("label_redemption_header_miles")} +{" "}
                {props?.priceBreakDownData?.totalFare}{" "}
                {props?.priceBreakDownData?.currency}
              </span>
            </div>
            <div className={styles.okButton}>
              <div className={styles.okBtn}>
                <button
                  className={styles.okBaseButton}
                  onClick={onClickClosePopup}
                >
                  <span className={styles.okTextLabel}>
                    {t("button_too_many_codes_ok")}
                  </span>
                </button>
              </div>
              <div className={styles.homeIndicatorMobile}>
                <span className={styles.homeIndicatorLine}></span>
              </div>
            </div>
          </div>
        </TGModal>
      )}
      {props.componentName === "BaggageAllowance" && (
        <TGModal
          show={show}
          handleClose={handleClose}
          buttonLabel="Ok"
          heading={t("review_info_baggage_allowance_label")}
          customModalClass={styles.baggageAllowanceModal}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <div className={styles.baggageDiv}>
            <div className={styles.strickyMobileAlign}>
              <table className={styles.baggageTable}>
                <thead>
                  <tr className={styles.tableHeader}>
                    <th className={styles.classHeaderLabel}>
                      {t("label_class")}
                    </th>
                    <th className={styles.allowanceHeaderLable}>
                      {t("review_info_allowance_label")}
                    </th>
                    <th className={styles.memberHeaderLable}>
                      {review_info_member}
                    </th>
                    <th className={styles.silverHeaderLable}>
                      {review_info_silver}
                    </th>
                    <th className={styles.goldHeaderLable}>
                      {review_info_gold}
                    </th>
                    <th className={styles.platinumHeaderLable}>
                      {review_info_platinum}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props?.baggageData?.map((baggageValues) => (
                    <tr className={styles.tableBody}>
                      <td className={styles.classTableBody}>
                        {baggageValues.class}
                      </td>
                      <td className={styles.allowanceTableBody}>
                        {baggageValues.allowance}
                      </td>
                      <td className={styles.memberTableBody}>
                        {baggageValues.member}
                      </td>
                      <td className={styles.silverTableBody}>
                        {baggageValues.silver}
                      </td>
                      <td className={styles.goldTableBody}>
                        {baggageValues.gold}
                      </td>
                      <td className={styles.platinumTableBody}>
                        {baggageValues.platinum}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* the below mobile view */}
              {props?.baggageData?.map((baggageValues, index) => (
                <div className={styles.baggageCardMobileView}>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className={styles.classesdetails}>
                          <div className={styles.classesAndDisc}>
                            <span className={styles.classLabel}>
                              {t("label_class")}
                            </span>
                            <span className={styles.classesDescriptions}>
                              {baggageValues.class}
                            </span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className={styles.allowanceAndKg}>
                          <div className={styles.allowanceAndKgDiv}>
                            <span className={styles.allowanceValues}>
                              {t("review_info_allowance_label")}
                            </span>
                            <span className={styles.dispalyBaggageValues}>
                              {baggageValues.allowance}
                            </span>
                          </div>
                        </div>
                        <div className={styles.allowanceAndKg}>
                          <div className={styles.allowanceAndKgDiv}>
                            <span className={styles.allowanceValues}>
                              {review_info_member}
                            </span>
                            <span className={styles.dispalyBaggageValues}>
                              {baggageValues.member}
                            </span>
                          </div>
                        </div>
                        <div className={styles.allowanceAndKg}>
                          <div className={styles.allowanceAndKgDiv}>
                            <span className={styles.allowanceValues}>
                              {review_info_silver}
                            </span>
                            <span className={styles.dispalyBaggageValues}>
                              {baggageValues.silver}
                            </span>
                          </div>
                        </div>
                        <div className={styles.allowanceAndKg}>
                          <div className={styles.allowanceAndKgDiv}>
                            <span className={styles.allowanceValues}>
                              {review_info_gold}
                            </span>
                            <span className={styles.dispalyBaggageValues}>
                              {baggageValues.gold}
                            </span>
                          </div>
                        </div>
                        <div className={styles.allowanceAndKg}>
                          <div className={styles.allowanceAndKgDiv}>
                            <span className={styles.allowanceValues}>
                              {review_info_platinum}
                            </span>
                            <span className={styles.dispalyBaggageValues}>
                              {baggageValues.platinum}
                            </span>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              ))}
              <span className={styles.additionalPieceText}>{baggage_info}</span>
              <br></br>
              <span className={styles.baggageAllowanceParagraphText}>
                {baggage_text}
              </span>
            </div>
            <div className={styles.okButton}>
              <div className={styles.okBtn}>
                <button
                  className={styles.okBaseButton}
                  onClick={onClickClosePopup}
                >
                  <span className={styles.okTextLabel}>
                    {t("button_too_many_codes_ok")}
                  </span>
                </button>
              </div>
              <div className={styles.homeIndicatorMobile}>
                <span className={styles.homeIndicatorLine}></span>
              </div>
            </div>
          </div>
        </TGModal>
      )}
      {props.componentName === "TermsAndConstions" && (
        <TGModal
          show={show}
          handleClose={handleClose}
          buttonLabel="Ok"
          heading={t("review_info_terms_and_conditions_label")}
          customModalClass={styles.termsAndConditionModal}
          customOffCanvasClass={styles.customOffCanvasClass}
          centered={true}
          backdrop="static"
        >
          <ol>
            {termsAndConditionsReviewInfo?.map((x: any) => (
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: x?.description + " " + x?.descriptionLink,
                  }}
                ></div>
                <br />
              </div>
            ))}
          </ol>
        </TGModal>
      )}
    </>
  );
};

export default ReviewInfoModals;
