import { useTranslation } from "react-i18next";
import moment from "moment";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./review-information.module.scss";
import TGIcon from "../../../../../shared/tg-icon";
import ReviewInfoModals from "./review-info-modals";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { retrivePNRRequest } from "../../../slice/retrivePNRSlice";
import ticketRestriction from "../../../../../../public/json/ticketRestriction.json";
import { calculateTimeLeft, camelCase } from "../../../../../utils/helper";
import Redemption from "../index";
import { airAwardReviewPnrRMRequest } from "../../../slice/airAwardReviewPnrRMSlice";
import {
  airAwardReviewPnrRequest,
  airAwardReviewPnrReset,
} from "../../../slice/airAwardReviewPnrSlice";
import {
  airAwardPaymentInitRequest,
  airAwardPaymentInitReset,
} from "../../../slice/airAwardPaymentInitSlice";
import { airAwardReviewPnrCancelRequest } from "../../../slice/airAwardReviewPnrCancelSlice";
import { Form, useNavigate } from "react-router-dom";
import { airAwardPnrReset } from "../../../slice/airAwardPnrSlice";
import { airAwardBookingReset } from "../../../slice/airAwardBookingSlice";
import { airAwardTstReset } from "../../../slice/airAwardTstSlice";
import { airAwardPriceReset } from "../../../slice/airAwardPriceSlice";
import { cancelBookingPNRRequest } from "../../../slice/cancelBookingPNRSlice";
import { resetPersistStore } from "../../../../../store";
import { savePageId } from "../../../slice/airAwardReviewPnrSlice";
import { constant } from "../../../../../config/constant";
import {
  resetButtonAction,
  resetModalTriggered,
  setError,
} from "../../../../../OSCI/src/slice/errorModalSlice";
import { config } from "../../../../../config/global";
import {
  getBaggageAllowanceFailure,
  getBaggageAllowanceSucess,
} from "../../../../../OSCI/src/slice/baggageAllowanceSlice";
import { clearSelectedFlightIndex, clearSelectedFlightInfoData, resetRedemeptionBookingData } from "../../../slice/flightInfoSlice";

const ReviewInformation: React.FC<any> = () => {
  const { review_info_member } = constant;
  const BAGGAGEALLOWANCEURL = config?.TEAMSITE_BAGGAGE_ALLOWANCE;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState<boolean>(false);
  const [modalPopupComponent, setModalPopupComponent] = useState("");
  const [priceBreakDownData, setPriceBreakDownData] = useState({});
  const [baggageData, setBaggageData] = useState([{}]);
  const [endTime, setEndTime] = useState(Date.now() + 10 * 60 * 1000);
  const formRef: any = useRef(null);
  const intervalRef: any = useRef(null);
  const airAwardReviewPnrData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardReviewPnr
  );
  const airAwardReviewPnrRMData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardReviewPnrRM
  );

  const airAwardReviewPnrCancelData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardReviewPnrCancel
  );

  const airAwardBookingData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardBooking
  );
  const airAwardPnrData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardPnr
  );
  const airAwardPaymentData = useSelector(
    (state: RootState) => state?.osciReducer?.airAwardPayment
  );

  const { buttonAction, errorTriggered } = useSelector(
    (state: RootState) => state?.osciReducer?.errorModal
  );

  const baggageAllowanceData = useSelector(
    (state: RootState) => state?.osciReducer?.baggageAllowance?.baggage
  );
  useEffect(() => {
    if (
      airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.success &&
      airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.session &&
      sessionStorage.getItem("entry") !== "2c2p"
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        session:
          airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.session,
      };
      if (
        airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.dataElement?.findIndex(
          (e: any) => e.qualifier === "FP"
        ) !== -1 &&
        sessionStorage.getItem("entry") !== "2c2p"
      ) {
        const qualifierNos =
          airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.dataElement
            ?.filter((x: any) => x?.qualifier === "FP")
            ?.map((x: any) => {
              return x?.code;
            });

        dispatch(
          airAwardReviewPnrCancelRequest({ ...raw, qualifierNos: qualifierNos })
        );
      } else {
        dispatch(airAwardReviewPnrRequest(raw));
      }
    }
  }, [
    airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.session?.sessionId,
    airAwardBookingData?.airAwardBookingInfo,
  ]);

  useEffect(() => {
    if (
      airAwardReviewPnrCancelData?.airAwardReviewPnrCancelInfo?.data?.success &&
      airAwardReviewPnrCancelData?.airAwardReviewPnrCancelInfo?.data?.session
        ?.sessionId &&
      sessionStorage.getItem("entry") !== "2c2p"
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        session:
          airAwardReviewPnrCancelData?.airAwardReviewPnrCancelInfo?.data
            ?.session,
      };
      dispatch(airAwardReviewPnrRequest(raw));
    }
  }, [
    airAwardReviewPnrCancelData?.airAwardReviewPnrCancelInfo?.data?.session
      ?.sessionId,
    airAwardBookingData?.airAwardBookingInfo,
    airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo,
  ]);

  useEffect(() => {
    if (buttonAction.buttonLabel === "primary" && buttonAction.value === true) {
      const reqData = {
        pnr: airAwardPnrData?.airAwardPnrInfo?.data?.pnr,
      };
      dispatch(retrivePNRRequest(reqData));
      dispatch(resetButtonAction());
    }
  }, [buttonAction]);

  useEffect(() => {
    if (
      airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.success &&
      airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session?.sessionId &&
      airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.success &&
      airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.session
        ?.sessionId &&
      Number(
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
          ?.sequenceNumber
      ) > 5 &&
      sessionStorage.getItem("entry") !== "2c2p"
    ) {
      const raw: any = {
        bookingRefId:
          airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
        qualifierNos:
          airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.dataElement
            ?.filter((x: any) => x?.qualifier === "TK")
            ?.map((x: any) => {
              return x?.code;
            }),
        session: airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session,
      };
      dispatch(airAwardPaymentInitRequest(raw));
    }
  }, [airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.success]);

  useEffect(() => {
    if (airAwardPnrData?.airAwardPnrInfo?.data?.pnr) {
      const reqData = {
        pnr: airAwardPnrData?.airAwardPnrInfo?.data?.pnr,
      };
      dispatch(retrivePNRRequest(reqData));
    }
  }, [airAwardPnrData?.airAwardPnrInfo?.data?.pnr, dispatch]);

  const flightDetails = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );

  useEffect(() => {
    if (airAwardPaymentData?.airAwardPaymentInitInfo?.data?.data) {
      formRef.current.action =
        airAwardPaymentData?.airAwardPaymentInitInfo?.data?.redirectionUrl;
      sessionStorage.setItem("entry", "2c2p");
      formRef?.current?.submit();
    }
  }, [airAwardPaymentData?.airAwardPaymentInitInfo?.data?.data]);

  useEffect(() => {
    if (intervalRef?.current) {
      clearInterval(intervalRef?.current);
    }
    dispatch(resetButtonAction());
    intervalRef.current = setInterval(() => {
      const time = calculateTimeLeft(endTime);
      const paymentFlag = !!sessionStorage?.getItem("entry");
      if (time.total <= 2 * 60 * 1000 && !paymentFlag) {
        let messageDetails = {
          heading: t("label_timer_msg_heading"),
          message: t("label_timer_msg_description"),
          buttonText: t("label_yes"),
          isLoginReq: false,
          isSecondaryButton: true,
          buttonTextSecondary: t("profile_cancel"),
          messageIcon: "timer-icon",
          type: "timer-details",
        };
        if (time.total < 2000) {
          dispatch(resetModalTriggered());
        } else {
          dispatch(setError(messageDetails));
        }
      }
      if (time.total <= 0) {
        let messageDetails = {
          heading: t("label_session_expired_heading"),
          message: t("label_session_expired_description"),
          buttonText: t("button_consent_back"),
          isLoginReq: false,
          isSecondaryButton: false,
          messageIcon: "expired-timer-icon",
          type: "expired-timer-details",
        };
        dispatch(setError(messageDetails));
        clearInterval(intervalRef?.current);
      }

      return () => clearInterval(intervalRef?.current);
    }, 1000);
  }, [endTime, t]);

  useEffect(() => {
    if (buttonAction?.value) {
      setEndTime(Date.now() + 10 * 60 * 1000);
    }
  }, [buttonAction]);

  useEffect(() => {
    setShowModal(false);
    dispatch(airAwardPaymentInitReset());
    dispatch({
      type: config.FETCH_DATA_CONSTANT,
      url: BAGGAGEALLOWANCEURL,
      isTeamsite: true,
      successAction: getBaggageAllowanceSucess,
      errorAction: getBaggageAllowanceFailure,
    });
    const date = new Date();
    sessionStorage.setItem("timestamp", date);
  }, []);

  // the below api response is getting from master code api
  const renderFlightDetails = (airportCode: any) => {
    const airportCodeVal = flightDetails?.filter(
      (data: { from: { airportCode: any } }) =>
        data?.from?.airportCode === airportCode
    )?.[0];
    return airportCodeVal;
  };

  const retrivePNRData = useSelector(
    (state: RootState) => state?.osciReducer?.retrivePNRData?.data?.data
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const confirmExit = (e) => {
    // e?.preventDefault();
    // e?.stopPropagation();
    // const leaveThisPage = window.confirm(t("review_info_cancel_confirmation_label"))
    // if (!leaveThisPage) {
    //   window.history.forward()
    // }
    // else {
    const reqData = {
      bookingRefId:
        airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
      session: {
        sessionId: retrivePNRData?.session?.sessionId,
        sequenceNumber: retrivePNRData?.session?.sequenceNumber,
        securityToken: retrivePNRData?.session?.securityToken,
      },
    };
    dispatch(cancelBookingPNRRequest(reqData));
    dispatch(airAwardPnrReset());
    dispatch(airAwardBookingReset());
    dispatch(airAwardTstReset());
    dispatch(airAwardPriceReset());
    dispatch(airAwardReviewPnrReset());
    resetPersistStore();
    dispatch(clearSelectedFlightInfoData());
    dispatch(clearSelectedFlightIndex());
    dispatch(resetRedemeptionBookingData());
    sessionStorage?.removeItem("entry");
    navigate("/");
    // }
  };

  window.onpopstate = (e: any) => {
    confirmExit(e);
  };

  const onClickContinueBtn = (e: any) => {
    e.preventDefault();
    setShowModal(true);
    setModalPopupComponent("Cancelbutton");
  };

  const onClickPaymentBtn = () => {
    if (isAgreed) {
      setShowErrorMsg(false);
      console.log("airAwardReviewPnrData", airAwardReviewPnrData);
      dispatch(airAwardPaymentInitReset());
      if (
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session &&
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
          ?.sequenceNumber !== "4" &&
        Number(
          airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
            ?.sequenceNumber
        ) > 5 &&
        sessionStorage.getItem("entry") === "2c2p"
      ) {
        const raw: any = {
          bookingRefId:
            airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
          qualifierNos:
            airAwardReviewPnrRMData?.airAwardReviewPnrRMInfo?.data?.dataElement
              ?.filter((x: any) => x?.qualifier === "TK")
              ?.map((x: any) => {
                return x?.code;
              }),
          session: retrivePNRData?.session,
        };
        dispatch(airAwardPaymentInitRequest(raw));
      } else if (
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.success &&
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session &&
        airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
          ?.sequenceNumber === "4" &&
        sessionStorage.getItem("entry") !== "2c2p"
      ) {
        const raw = {
          bookingRefId:
            airAwardBookingData?.airAwardBookingInfo?.data?.bookingRefId,
          session: {
            sessionId:
              airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
                ?.sessionId,
            sequenceNumber:
              airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
                ?.sequenceNumber,
            securityToken:
              airAwardReviewPnrData?.airAwardReviewPnrInfo?.data?.session
                ?.securityToken,
          },
        };
        dispatch(airAwardReviewPnrRMRequest(raw));
        dispatch(airAwardReviewPnrReset());
      }
    } else {
      setShowErrorMsg(true);
    }
  };

  const onClickAgree = () => {
    if (!isAgreed) {
      setIsAgreed(true);
      setShowErrorMsg(false);
    }
    if (isAgreed) {
      setIsAgreed(false);
    }
  };

  const onClickPriceBreakDown = (retrivePNRData: {}) => {
    setShowModal(true);
    setModalPopupComponent("PriceBreakDown");
    setPriceBreakDownData(retrivePNRData);
  };

  const onClickTermsAndCondtions = () => {
    setShowModal(true);
    setModalPopupComponent("TermsAndConstions");
  };

  const onClickBaggageAllowance = (baggageAllowance: any) => {
    setBaggageData(baggageAllowanceData);
    setShowModal(true);
    setModalPopupComponent("BaggageAllowance");
  };

  const displayNameInfo = () => {
    const userName = sessionStorage.getItem("FullName");
    const name = userName && userName.split(" ");
    const displayName = name && name[1] ? name[1] : "";
    const lastName =
      userName && userName?.split(" ")[userName?.split(" ").length - 1];
    return (
      displayName?.charAt(0).toUpperCase() +
      displayName?.slice(1).toLowerCase() +
      " " +
      lastName?.charAt(0).toUpperCase() +
      lastName?.slice(1).toLowerCase()
    );
  };

  const ticketRestrictionData = (
    ticketRestriction: { ticketRestriction: string }[]
  ) => {
    const nestedItems = ticketRestriction?.map((nestedItem: any) => (
      <li>{nestedItem?.ticketRestriction}</li>
    ));
    return <ul>{nestedItems}</ul>;
  };

  return (
    <>
      <Redemption />
      <div className={`${styles.reviewInfoContainer} global-content-padding`}>
        <div className={styles.reviewInfo}>
          <div className={styles.reviewInfoDiv}>
            <span className={styles.reviewInfoLabel}>
              {t("review_information_label")}
            </span>
            <div className={styles.reviewInfoFightDetails}>
              <div className={styles.flightDetails}>
                <div className={styles.flightDetailsWithContinueBtn}>
                  <div className={styles.flightDetailsWithTermsCondition}>
                    <div className={styles.flightDetailsWithPaymentView}>
                      <div className={styles.flightInfoWithMemberDetails}>
                        <div className={styles.memberDetailsDiv}>
                          <div className={styles.memberWithFlightLayout}>
                            <div className={styles.memberAndFlightName}>
                              <div className={styles.memberAndFlightNameBottom}>
                                <div className={styles.memberAndFlightNameDiv}>
                                  <div className={styles.memberName}>
                                    <span
                                      className={
                                        styles["reviewInformationHeading"]
                                      }
                                    >
                                      {t("label_enrolment_consent_member_name")}
                                    </span>
                                    <div className={styles.flightNum_Details}>
                                      <div
                                        className={styles.flightNumDivBorder}
                                      >
                                        <span
                                          className={
                                            styles["review-info-label-big"]
                                          }
                                        >
                                          {displayNameInfo()}
                                        </span>
                                        <span
                                          className={
                                            styles["review-info-label-big"]
                                          }
                                        >
                                          | {sessionStorage.getItem("memberId")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.flightinfoCont}>
                              <div className={styles.flightInfoDiv}>
                                <div className={styles.flightInfoLabelDiv}>
                                  <span
                                    className={
                                      styles["reviewInformationHeading"]
                                    }
                                  >
                                    {t("review_info_flight_information_label")}
                                  </span>
                                </div>

                                <div className={styles.DeptArrivalDiv}>
                                  {retrivePNRData?.itineraryInfos?.map(
                                    (flightInfoResponse: any, index: any) => (
                                      <div className={styles.flightDetailsList}>
                                        <div className={styles.departAndClass}>
                                          <div
                                            className={
                                              styles.departArrivalAndLocation
                                            }
                                          >
                                            <span
                                              className={
                                                styles["review-info-label"]
                                              }
                                            >
                                              {renderFlightDetails(
                                                flightInfoResponse?.origin
                                              )?.triptype === "departure"
                                                ? "Departure"
                                                : "Return"}
                                            </span>
                                            <div
                                              className={styles.originAndDepart}
                                            >
                                              <span
                                                className={
                                                  styles[
                                                    "reviewInformationSubHeading"
                                                  ]
                                                }
                                              >
                                                {
                                                  renderFlightDetails(
                                                    flightInfoResponse?.origin
                                                  )?.from?.cityName
                                                }{" "}
                                                -{" "}
                                                {
                                                  renderFlightDetails(
                                                    flightInfoResponse?.origin
                                                  )?.to?.cityName
                                                }
                                              </span>
                                            </div>
                                          </div>
                                          <div className={styles.classes}>
                                            <span
                                              className={
                                                styles["review-info-label"]
                                              }
                                            >
                                              {t("label_class")}
                                            </span>
                                            <div
                                              className={styles.displayClasses}
                                            >
                                              <span
                                                className={
                                                  styles[
                                                    "reviewInformationSubHeading"
                                                  ]
                                                }
                                              >
                                                {
                                                  flightInfoResponse?.bookingClassName
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            styles.flightDetailsCardBorder
                                          }
                                        >
                                          <div
                                            className={styles.flightDetailsCard}
                                          >
                                            <div
                                              className={
                                                styles.originDestignation
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.originDestignationDiv
                                                }
                                              >
                                                <div
                                                  className={styles.originView}
                                                >
                                                  <div
                                                    className={
                                                      styles.timeAndDate
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-data-big"
                                                        ]
                                                      }
                                                    >
                                                      {moment(
                                                        flightInfoResponse?.departureTime,
                                                        "HHmm"
                                                      ).format("hh:mm")}
                                                    </span>
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-data-medium"
                                                        ]
                                                      }
                                                    >
                                                      {moment(
                                                        flightInfoResponse?.departureDate,
                                                        "DDMMYY"
                                                      ).format(
                                                        "ddd, DD MMM YYYY"
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={styles.location}
                                                  >
                                                    <div
                                                      className={
                                                        styles.originDestn
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles[
                                                            "reviewInformationHeading"
                                                          ]
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.origin
                                                        }
                                                      </span>
                                                      <span
                                                        className={
                                                          styles[
                                                            "review-info-data-medium"
                                                          ]
                                                        }
                                                      >
                                                        {
                                                          renderFlightDetails(
                                                            flightInfoResponse?.origin
                                                          )?.from?.cityName
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.airportLocation
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-label-small"
                                                        ]
                                                      }
                                                    >
                                                      {
                                                        renderFlightDetails(
                                                          flightInfoResponse?.origin
                                                        )?.from?.airportName
                                                      }
                                                    </span>
                                                    <span
                                                      className={
                                                        styles.terminal
                                                      }
                                                    >
                                                      {/* Terminal 2 */}
                                                    </span>
                                                  </div>
                                                </div>

                                                <div
                                                  className={
                                                    styles.hoursAndstops
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.displayHours
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles.displayHoursValue
                                                      }
                                                    >
                                                      {moment
                                                        .utc(
                                                          moment(
                                                            flightInfoResponse?.arrivalTime,
                                                            "HHmm"
                                                          ).diff(
                                                            moment(
                                                              flightInfoResponse?.departureTime,
                                                              "HHmm"
                                                            )
                                                          )
                                                        )
                                                        .format(
                                                          "h [h] m [min]"
                                                        )}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.flightIconDiv
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.flightIconHypen
                                                      }
                                                    ></div>
                                                    <div
                                                      className={
                                                        styles.flightIcon
                                                      }
                                                    >
                                                      <TGIcon
                                                        icon="review-info-fight-icon"
                                                        fillColor=""
                                                        size="10px"
                                                      />
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.flightIconHypen
                                                      }
                                                    ></div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.stopORNonStop
                                                    }
                                                  >
                                                    {flightInfoResponse?.numOfStops !==
                                                    "0" ? (
                                                      <span
                                                        className={
                                                          styles.stopORNonStopLabel
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.numOfStops
                                                        }{" "}
                                                        {t(
                                                          "label_redemption_stop"
                                                        )}
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className={
                                                          styles.stopORNonStopLabel
                                                        }
                                                      >
                                                        {t("label_nonstop")}
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>

                                                <div
                                                  className={styles.destinView}
                                                >
                                                  <div
                                                    className={
                                                      styles.timeAndDate
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-data-big"
                                                        ]
                                                      }
                                                    >
                                                      {moment(
                                                        flightInfoResponse?.arrivalTime,
                                                        "HHmm"
                                                      ).format("hh:mm")}
                                                    </span>
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-data-medium"
                                                        ]
                                                      }
                                                    >
                                                      {moment(
                                                        flightInfoResponse?.arrivalDate,
                                                        "DDMMYY"
                                                      ).format(
                                                        "ddd, DD MMM YYYY"
                                                      )}
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={styles.location}
                                                  >
                                                    <div
                                                      className={
                                                        styles.originDestn
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles[
                                                            "reviewInformationHeading"
                                                          ]
                                                        }
                                                      >
                                                        {
                                                          flightInfoResponse?.destination
                                                        }
                                                      </span>
                                                      <span
                                                        className={
                                                          styles[
                                                            "review-info-data-medium"
                                                          ]
                                                        }
                                                      >
                                                        {
                                                          renderFlightDetails(
                                                            flightInfoResponse?.origin
                                                          )?.to?.cityName
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.airportLocation
                                                    }
                                                  >
                                                    <span
                                                      className={
                                                        styles[
                                                          "review-info-label-small"
                                                        ]
                                                      }
                                                    >
                                                      {
                                                        renderFlightDetails(
                                                          flightInfoResponse?.origin
                                                        )?.to?.airportName
                                                      }
                                                    </span>
                                                    <span
                                                      className={
                                                        styles.terminal
                                                      }
                                                    >
                                                      {/* Terminal 2 */}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className={styles.logoView}>
                                              <div
                                                className={
                                                  styles.thaiLogoAndFlightNum
                                                }
                                              >
                                                <div
                                                  className={styles.thaiLogo}
                                                >
                                                  <TGIcon
                                                    icon="review-info-thai-logo"
                                                    fillColor=""
                                                    size="20px"
                                                  />
                                                  <TGIcon
                                                    icon="review-info-thai-logo-text"
                                                    fillColor=""
                                                    size="40px"
                                                  />
                                                </div>
                                                <span
                                                  className={
                                                    styles[
                                                      "review-info-data-medium-normal"
                                                    ]
                                                  }
                                                >
                                                  {flightInfoResponse?.mc}{" "}
                                                  {
                                                    flightInfoResponse?.flightNumber
                                                  }
                                                </span>
                                              </div>
                                              <span
                                                className={
                                                  styles.flightNumAndCode
                                                }
                                              >
                                                {/* Airbus A350-800 */}
                                              </span>
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={styles.passengersLayout}>
                            {/* Passengers */}
                            <div className={styles.passengersDiv}>
                              <div className={styles.passengersViewAlign}>
                                <div className={styles.passengersBox}>
                                  <div className={styles.passengersLabelDiv}>
                                    <span
                                      className={
                                        styles["reviewInformationHeading"]
                                      }
                                    >
                                      {t("label_book_widget_passengers")}
                                    </span>
                                  </div>
                                </div>
                                {retrivePNRData?.fareInfo?.map(
                                  (fareInfoDetails: any, index: any) => (
                                    <div className={styles.contactInfoCard}>
                                      <div className={styles.fareDetailsView}>
                                        <div
                                          className={styles.fareDetailsLayout}
                                        >
                                          <div
                                            className={
                                              styles.displayFareDetailsCrad
                                            }
                                          >
                                            <div
                                              className={
                                                styles.displayFareDetails
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.fareMemberDetailsView
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.passengerTextColourAndMember
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles[
                                                        "reviewInformationSubHeading"
                                                      ]
                                                    }
                                                  >
                                                    {fareInfoDetails?.firstName &&
                                                      camelCase(
                                                        fareInfoDetails?.firstName
                                                      )}{" "}
                                                    {fareInfoDetails?.lastName &&
                                                      camelCase(
                                                        fareInfoDetails?.lastName
                                                      )}
                                                  </span>
                                                  <span
                                                    className={
                                                      styles[
                                                        "reviewInformationSubHeading"
                                                      ]
                                                    }
                                                  >
                                                    {review_info_member}
                                                  </span>
                                                </div>
                                                <div
                                                  className={
                                                    styles.fareAndMiles
                                                  }
                                                >
                                                  <span
                                                    className={styles.fareLabel}
                                                  >
                                                    {t(
                                                      "label_redemption_summary_fare"
                                                    )}
                                                  </span>
                                                  <span
                                                    className={styles.milesData}
                                                  >
                                                    {
                                                      fareInfoDetails?.totalMiles
                                                    }{" "}
                                                    {t(
                                                      "label_redemption_header_miles"
                                                    )}
                                                  </span>
                                                </div>
                                                <div
                                                  className={
                                                    styles.taxesAndSurchages
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles.taxesAndSurchagesLabel
                                                    }
                                                  >
                                                    {t(
                                                      "label_redemption_summary_taxes_and_surchanges"
                                                    )}
                                                  </span>
                                                  <span
                                                    className={
                                                      styles.taxesAndSurchagesData
                                                    }
                                                  >
                                                    {fareInfoDetails?.currency}{" "}
                                                    {
                                                      fareInfoDetails?.totalFares
                                                    }
                                                  </span>
                                                </div>
                                              </div>

                                              <div
                                                className={
                                                  styles["baggageDetailsView"]
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles[
                                                      "passengerTextColourAndMember"
                                                    ]
                                                  }
                                                >
                                                  <span
                                                    className={
                                                      styles[
                                                        "review-info-data-medium"
                                                      ]
                                                    }
                                                  >
                                                    {t("label_baggage")}
                                                  </span>
                                                </div>
                                                {fareInfoDetails.baggage.map(
                                                  (
                                                    item: any,
                                                    index: number
                                                  ) => (
                                                    <div
                                                      className={
                                                        styles["fareAndMiles"]
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles["fareLabel"]
                                                        }
                                                      >
                                                        {item.origin} -{" "}
                                                        {item.destination}
                                                      </span>
                                                      <span
                                                        className={
                                                          styles["milesData"]
                                                        }
                                                      >
                                                        {item.allowance}
                                                      </span>
                                                    </div>
                                                  )
                                                )}
                                              </div>

                                              <div
                                                className={
                                                  styles.baggagAllowance
                                                }
                                              >
                                                <div
                                                  className={
                                                    styles.baggagAllowanceDiv
                                                  }
                                                  onClick={() =>
                                                    onClickBaggageAllowance(
                                                      fareInfoDetails?.baggage
                                                    )
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.baggagAllowanceAlign
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.baggagAllowanceLabel
                                                      }
                                                    >
                                                      {t(
                                                        "review_info_baggage_allowance_label"
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.baggagAllowanceRightArrowIcon
                                                      }
                                                    >
                                                      <TGIcon
                                                        icon="review-info-baggage-right-arrow-icon"
                                                        fillColor=""
                                                        size="10px"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              {/* contact information */}
                              <div className={styles.contactInfoDiv}>
                                <div className={styles.contactInforLabelDiv}>
                                  <span className={styles.contactInfoLabel}>
                                    {t("review_info_contact_info_label")}
                                  </span>
                                </div>
                                <div className={styles.phoneNumAndEmail}>
                                  <div className={styles.phoneNum}>
                                    <span className={styles.phoneNumLabel}>
                                      {t("profile_view_contact")}
                                    </span>
                                    <div className={styles.phoneNumValueCode}>
                                      <div>
                                        <span>+</span>
                                      </div>
                                      <span className={styles.phoneNumValue}>
                                        {retrivePNRData?.phoneNum ||
                                          "678314944"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className={styles.emailID}>
                                    <span className={styles.emailLabel}>
                                      {t("label_reset_email")}
                                    </span>
                                    <span className={styles.emailIdValue}>
                                      {retrivePNRData?.email
                                        ? camelCase(retrivePNRData?.email)
                                        : "thaitestuser13@gmail.com"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.paymentMethodDiv}>
                        {/* Acceptance payments method */}
                        <div className={styles.paymentAndItinerary}>
                          <div className={styles.paymentDiv}>
                            <span className={styles.acceptedPaymentLabel}>
                              {t("review_info_accept_payment_label")}
                            </span>
                            <div className={styles.displayPaymentCards}>
                              <div className={styles.visaCard}>
                                <TGIcon
                                  icon="review-info-payment-visa-icon"
                                  fillColor=""
                                  size="10px"
                                />
                              </div>
                              <div className={styles.masterCard}>
                                <TGIcon
                                  icon="review-info-payment-master-card-icon"
                                  fillColor=""
                                  size="10px"
                                />
                              </div>
                              <div className={styles.amexCard}>
                                <TGIcon
                                  icon="review-info-payment-amex-card-icon"
                                  fillColor=""
                                  size="10px"
                                />
                              </div>
                              <div className={styles.jcbCard}>
                                <TGIcon
                                  icon="review-info-payment-jcb-card-icon"
                                  fillColor=""
                                  size="10px"
                                />
                              </div>
                            </div>
                          </div>
                          <div className={styles.itinaryPricingDiv}>
                            <span className={styles.itinaryPricingLabel}>
                              {t("review_info_itineraty_pricing_label")}
                            </span>
                            <div className={styles.totalmilesDiv}>
                              <div className={styles.totalmilesAndTaxes}>
                                <div className={styles.totalmilesAndFares}>
                                  <div className={styles.totalmilesAndFaresDiv}>
                                    <span
                                      className={
                                        styles["review-info-data-medium"]
                                      }
                                    >
                                      {t("review_info_total_miles_used_label")}
                                    </span>
                                    <span className={styles.totalMilesValues}>
                                      {retrivePNRData?.totalMiles}
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      styles.applicableTaxesAndSurchages
                                    }
                                  >
                                    <span
                                      className={
                                        styles.applicableTaxesAndSurchagesLabel
                                      }
                                    >
                                      {t(
                                        "review_info_taxes_and_surcharges_label"
                                      )}
                                    </span>
                                    <span
                                      className={
                                        styles.applicableTaxesAndSurchagesData
                                      }
                                    >
                                      {retrivePNRData?.currency}{" "}
                                      {retrivePNRData?.totalFare}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.priceBreakdown}>
                                <div
                                  className={styles.priceBreakdownDiv}
                                  onClick={() =>
                                    onClickPriceBreakDown(retrivePNRData)
                                  }
                                >
                                  <div
                                    className={styles.priceBreakdownLabelDiv}
                                  >
                                    <div className={styles.priceBreakdownLabel}>
                                      {t("review_info_price_breakdown_label")}
                                    </div>
                                    <div
                                      className={
                                        styles.baggagAllowanceRightArrowIcon
                                      }
                                    >
                                      <TGIcon
                                        icon="review-info-right-arrow"
                                        fillColor=""
                                        size="10px"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span className={styles.ticketRestrictionLabel}>
                                {t("review_info_ticket_restriction_label")}:
                                {ticketRestrictionData(ticketRestriction)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.termsAndConditionDiv}>
                      <div className={styles.horizontalVectorIcon}>
                        <div className={styles.verticalLineTerms}></div>
                        <div className={styles.termsAndConditionBorder}>
                          <div
                            className={styles.checkboxInput}
                            onClick={onClickAgree}
                          >
                            {isAgreed && (
                              <TGIcon
                                icon="review-info-checkbox-tick"
                                fillColor=""
                                size="10px"
                              />
                            )}
                          </div>
                          <span className={styles.AcceptAllLabel}>
                            {t("profile_accept")}
                            <span
                              className={styles.termsAndCondtionLabel}
                              onClick={onClickTermsAndCondtions}
                            >
                              {t("profile_terms")}
                            </span>
                          </span>
                        </div>
                      </div>
                      {showErrorMsg && (
                        <div className={styles.errorMsgDiv}>
                          <div className={styles.errorMsgIcon}>
                            <TGIcon
                              icon="review-info-alert-circle-icon"
                              fillColor=""
                              size="10px"
                            />
                          </div>
                          <div className={styles.termsAndCondtionErrorMsg}>
                            <span
                              className={styles.termsAndConditionErrMsgLabel}
                            >
                              {t("enroll_terms_condition")}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.cancelBtnDiv}>
                    <div className={styles.cancelAndProceedBtnDiv}>
                      <div className={styles.cancelButtonDiv}>
                        <button
                          className={styles.cancelButton}
                          onClick={onClickContinueBtn}
                        >
                          <span className={styles.cancelLabel}>
                            {t("profile_cancel")}
                          </span>
                        </button>
                      </div>
                      <div className={styles.proceedToPaymentBtnDiv}>
                        <button
                          className={styles.proceedToPaymentBtn}
                          onClick={onClickPaymentBtn}
                        >
                          {isDesktopView && (
                            <span
                              className={styles.proceedToPaymentLabelDesktop}
                            >
                              {t("review_info_procceed_to_payment")}
                            </span>
                          )}
                          {isMobile && (
                            <span
                              className={styles.proceedToPaymentLabelDesktop}
                            >
                              {t("review_info_confirm_to_payment")}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ReviewInfoModals
          hidePanel={handleCloseModal}
          componentName={modalPopupComponent}
          baggageData={baggageData}
          priceBreakDownData={priceBreakDownData}
          retrivePNRData={retrivePNRData}
        />
      )}
      <Form method="post" id="mypaymentform" ref={formRef}>
        <input
          type="hidden"
          id="apiRequest"
          name="apiRequest"
          value={airAwardPaymentData?.airAwardPaymentInitInfo?.data?.data}
        />
      </Form>
    </>
  );
};

export default ReviewInformation;
